import React from 'react';
import { Box, InputGroup, InputRightElement, Input } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

const Search = ({
  size,
  borderRadius,
  placeholder,
  type,
  onChange,
  my,
  value,
  onKeyDown,
}) => {
  return (
    <Box my={my}>
      <InputGroup size={size}>
        <InputRightElement
          pointerEvents="none"
          children={<Search2Icon color="gray.300" />}
        />
        <Input
          type={type}
          placeholder={placeholder}
          size={size}
          borderRadius={borderRadius}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
      </InputGroup>
    </Box>
  );
};

export default Search;
