import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListUsers } from '../../actions/githubActions';
import {
  Box,
  Flex,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { Search, CardUser, Pagination } from '../../components';

const Home = () => {
  const [keySearch, setKeySearch] = useState('');
  const [search, setSearch] = useState('');

  const [perPage] = useState(12);
  const [page, setPage] = useState(1);

  const { getListUsersResult, getListUsersLoading } = useSelector(
    (state) => state.GithubReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (search) {
      dispatch(getListUsers(perPage, page, search));
    }
  }, [dispatch, search, page, perPage]);

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setSearch(keySearch);
    }
  };

  return (
    <>
      <Search
        size="lg"
        borderRadius="20"
        placeholder="Search"
        type="text"
        my={9}
        value={keySearch}
        onChange={(e) => setKeySearch(e.target.value)}
        onKeyDown={handleSearch}
      />

      <SimpleGrid columns={[1, 4, 4]} spacing={8}>
        {getListUsersResult ? (
          getListUsersResult?.items?.map((user, idx) => {
            return <CardUser item={user} id={idx} />;
          })
        ) : getListUsersLoading ? (
          [...Array(10)].map((e, i) => {
            return (
              <Box bg="white" key={i}>
                <Skeleton height="284px" borderRadius={10} />
                <SkeletonText mt="4" noOfLines={3} spacing="4" />
              </Box>
            );
          })
        ) : (
          <Flex>
            <Text fontSize={'small'}>Empty. Please Search!</Text>
          </Flex>
        )}
      </SimpleGrid>

      {getListUsersResult ? (
        <Pagination
          setPage={setPage}
          page={page}
          totalRows={getListUsersResult?.total_count}
          limit={perPage}
        />
      ) : null}
    </>
  );
};

export default Home;
