import { Box, Button } from '@chakra-ui/react';
import React from 'react';

const Pagination = ({ setPage, page, totalRows, limit }) => {
  const totalPages = Math.ceil(totalRows / limit);
  const isLastPage = page === totalPages;

  const handleNextPage = () => {
    if (!isLastPage) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <Box py={10} justifyContent={'center'} display={'flex'}>
      <Button
        isDisabled={page === 1}
        colorScheme="teal"
        onClick={() => handlePrevPage()}
      >
        Prev
      </Button>
      <Button colorScheme="gray" variant="outline" mx={2}>
        {page}
      </Button>
      <Button
        isDisabled={isLastPage}
        colorScheme="teal"
        onClick={() => handleNextPage()}
      >
        Next
      </Button>
    </Box>
  );
};

export default Pagination;
