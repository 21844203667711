import axios from 'axios';
import { BASE_URL } from '../utils/url';

export const GET_LIST_USERS = 'GET_LIST_USERS';

export const getListUsers = (per_page, page, q) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_USERS,
      payload: {
        loading: true,
        data: false,
        errorMessage: false,
      },
    });
    axios({
      method: 'GET',
      url: `${BASE_URL}/search/users`,
      params: {
        per_page,
        page,
        q,
      },
    })
      .then((response) => {
        dispatch({
          type: GET_LIST_USERS,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_USERS,
          payload: {
            loading: false,
            data: false,
            errorMessage: error.message,
          },
        });
      });
  };
};
