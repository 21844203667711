import { Flex, chakra } from '@chakra-ui/react';
import React from 'react';
import './index.css';

const Header = () => {
  return (
    <Flex alignItems={'center'} justify={'center'} mt={10}>
      <chakra.h1
        className="title"
        fontSize="x-large"
        fontWeight="bold"
        color={'#4E4F50'}
      >
        Github User Finder
      </chakra.h1>
    </Flex>
  );
};

export default Header;
