import Layout from './components/layout';
import { Home } from './pages';

function App() {
  return (
    <>
      <Layout>
        <Home />
      </Layout>
    </>
  );
}

export default App;
