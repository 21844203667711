import React from 'react';
import { Box, Button, Flex, Image, chakra, Link } from '@chakra-ui/react';

const CardUser = ({ id, item }) => {
  return (
    <Flex w="full" alignItems="center" justifyContent="center" key={id}>
      <Box
        w="xs"
        bg="white"
        _dark={{
          bg: 'gray.800',
        }}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        mx="auto"
      >
        <Image
          w="full"
          h="full"
          fit="contain"
          src={item.avatar_url}
          alt={item.login}
        />

        <Box p={2}>
          <chakra.h1
            fontSize="lg"
            fontWeight="bold"
            mt={1}
            color="gray.800"
            _dark={{
              color: 'white',
            }}
          >
            {item.login}
          </chakra.h1>
        </Box>
        <Box px={2} pb={4}>
          <Link href={item.html_url} isExternal>
            <Button
              w={'100%'}
              bg="#4E4F50"
              _hover={{
                bg: '#4E4F50',
              }}
              _focus={{
                bg: '#4E4F50',
              }}
              size="sm"
              color={'white'}
            >
              More
            </Button>
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};

export default CardUser;
