import { Container } from '@chakra-ui/react';
import React from 'react';
import Header from '../Header';

const Layout = ({ children }) => {
  return (
    <Container maxW="container.md">
      <Header />
      {children}
    </Container>
  );
};

export default Layout;
